import React from 'react'
import Fragment from '../common/fragment'

function FragmentDB(props) {
  return (
    <Fragment pos="bbbb" {...props}>
      <p>
      Que Duchamp, Marcel, llevó a Nueva York, para venderlo
      </p>
    </Fragment>
  )
}


export default FragmentDB
