import React from 'react'
import Fragment from '../common/fragment'


function FragmentCT(props) {
  return (
    <Fragment pos="ttt" {...props}>
      <p>
No se puede traicionar al aire, ni entregar por tres monedas lo que no nos
pertenece. Todo es propiedad privada, rodeado de vallas y otras zanjas.
      </p>
    </Fragment>
  )
}


export default FragmentCT
