import React from 'react'
import Fragment from '../common/fragment'


function FragmentBC(props) {
  return (
    <Fragment pos="cc" {...props}>
      <p>
Pequeño oratorio a los desiertos.
      </p>
    </Fragment>
  )
}


export default FragmentBC
