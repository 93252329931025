import React from 'react'


function Empty() {
  return (
    <div style={{ height: '80vh' }} />
  )
}


export default Empty
