import React from 'react'
import Fragment from '../common/fragment'


function FragmentAF(props) {
  return (
    <Fragment pos="f" {...props}>
      <p>
En el valle hay androides, replicantes, zombis, robots industriales, manos
articuladas, peluches, cadáveres, cuerpos sanos.
      </p>
    </Fragment>
  )
}


export default FragmentAF
