import React from 'react'
import Fragment from '../common/fragment'


function FragmentAL(props) {
  return (
    <Fragment pos="l" {...props}>
      <p>
En Salamanca, al caer la tarde, los predicadores le hablan a la muerte, por unos
altavoces, para que nada impidiera que sus cuerpos. Qué nada impida la muerte.
      </p>
    </Fragment>
  )
}


export default FragmentAL
