import React from 'react'
import Collection from '../common/collection'

function CollectionI({ props }) {
  return (
    <Collection
      src={[
        'https://volverse-negro-daniel.s3.amazonaws.com/images/8.+palomas+refiner%C3%ADa10.jpg',
      ]}
      alt="Palomas"
    />
  )
}


export default CollectionI
