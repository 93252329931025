import React from 'react'
import Fragment from '../common/fragment'


function FragmentCU(props) {
  return (
    <Fragment pos="uuu" {...props}>
      <p>
Viene al caso recordar los corderos que balan en los valles. Viene al caso, recordar
la extrañeza y la inquietud. Los androides y el pulso de las manos articuladas. El
Muñeco y sus abejas. El zumbido de los trenes, que desaparecen en colmenas de
hierro.
      </p>
    </Fragment>
  )
}


export default FragmentCU
