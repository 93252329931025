import React from 'react'
import Fragment from '../common/fragment'

function FragmentDC(props) {
  return (
    <Fragment pos="cccc" {...props}>
      <p>
      Dice: “Air de Paris”, en el francés gótico de los mecenas y los vagabundos.
      </p>
    </Fragment>
  )
}


export default FragmentDC
