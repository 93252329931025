import React from 'react'
import Fragment from '../common/fragment'


function FragmentDG(props) {
  return (
    <Fragment pos="gggg" {...props}>
      <p>
Estamos en septiembre y pronto llegará el otoño.
      </p>
    </Fragment>
  )
}


export default FragmentDG
