import React from 'react'
import Collection from '../common/collection'

function CollectionE({ props }) {
  return (
    <Collection
      src={[
        'https://volverse-negro-daniel.s3.amazonaws.com/images/5.+ni%C3%B1oturbina69.jpg',
      ]}
      alt="Niño turbina"
    />
  )
}


export default CollectionE
