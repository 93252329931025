import React from 'react'
import Zoom from '../common/zoom'


function ZoomD(props) {
  return (
    <Zoom
      {...props}
      poster="https://volverse-negro-daniel.s3.amazonaws.com/stills/zoom-4.jpg"
      src="https://volverse-negro-daniel.s3.amazonaws.com/videos/zoom-4.mp4"
    />
  )
}


export default ZoomD
