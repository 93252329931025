import React from 'react'
import Fragment from '../common/fragment'


function FragmentCZ(props) {
  return (
    <Fragment pos="zzz" {...props}>
      <p>
Mire, éste es el aire de París
      </p>
    </Fragment>
  )
}


export default FragmentCZ
