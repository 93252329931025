import React from 'react'
import Fragment from '../common/fragment'


function FragmentDP(props) {
  return (
    <Fragment pos="pppp" {...props}>
      <p>
Hay tantos tonos de negro, como un degradé de la oscuridad en tantos tonos de
negro, negrocristo, negrojudas, negromex, negromaximiliano, negronegronegro.
Negro marfil, negro humo, gris de Payne.
      </p>
    </Fragment>
  )
}


export default FragmentDP
