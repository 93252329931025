import React from 'react'
import Collection from '../common/collection'

function CollectionX({ props }) {
  return (
    <Collection
      src={['https://volverse-negro-daniel.s3.amazonaws.com/images/23.+bayas62.jpg']}
      alt="Bayas"
    />
  )
}


export default CollectionX
