import React from 'react'
import Fragment from '../common/fragment'


function FragmentBQ(props) {
  return (
    <Fragment pos="qq" {...props}>
      <p>
El Caballo era un regalo y también la derrota.
      </p>
    </Fragment>
  )
}


export default FragmentBQ
