import React from 'react'
import Fragment from '../common/fragment'


function FragmentDI(props) {
  return (
    <Fragment pos="iiii" {...props}>
      <p>
Judas negro. Vieja esclavitud y serpientes. Historia y geología. Fiestas de Semana
Santa.
      </p>
    </Fragment>
  )
}


export default FragmentDI
