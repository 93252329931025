import React from 'react'
import Fragment from '../common/fragment'


function FragmentAG(props) {
  return (
    <Fragment pos="g" {...props}>
      <p>
No se mencionan ovejas ni predicadores.
      </p>
    </Fragment>
  )
}


export default FragmentAG
