import React from 'react'
import Fragment from '../common/fragment'

function FragmentDS(props) {
  return (
    <Fragment pos="ssss" {...props}>
      <p>
      El negro es propiedad privada: no pasar.
      </p>
    </Fragment>
  )
}


export default FragmentDS
