import React from 'react'
import Zoom from '../common/zoom'


function ZoomC(props) {
  return (
    <Zoom
      {...props}
      poster="https://volverse-negro-daniel.s3.amazonaws.com/stills/zoom-3.jpg"
      src="https://volverse-negro-daniel.s3.amazonaws.com/videos/zoom-3.mp4"
    />
  )
}


export default ZoomC
