export default [
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(1).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(2).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(3).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(4).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(5).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(6).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(7).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(8).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(9).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(10).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(11).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(12).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(13).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(14).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(15).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(16).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(17).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(18).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(19).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(20).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(21).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(22).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(23).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(24).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(25).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(26).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(27).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(28).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(29).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(30).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(31).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(32).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(33).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(34).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(35).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(36).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(37).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(38).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(39).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(40).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(41).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(42).jpg',
  'https://volverse-negro-daniel.s3.amazonaws.com/figures/figure+(43).jpg',
]
