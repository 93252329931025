import React from 'react'
import Fragment from '../common/fragment'


function FragmentCD(props) {
  return (
    <Fragment pos="ddd" {...props}>
      <p>
En la noche, arañas y el canto de grillos y cigarras. Mosquitos y otros animales
nocturnos.
      </p>
    </Fragment>
  )
}


export default FragmentCD
