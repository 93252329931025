import React from 'react'
import Fragment from '../common/fragment'

function FragmentCH(props) {
  return (
    <Fragment pos="hhh" {...props}>
      <p>
Toda propiedad es privada y está circundada de muros.
      </p>
    </Fragment>
  )
}


export default FragmentCH
