import React from 'react'
import Fragment from '../common/fragment'


function FragmentCK(props) {
  return (
    <Fragment pos="kkk" {...props}>
      <p>
      Todo tiene un dueño. “No pasar”.
      </p>
    </Fragment>
  )
}


export default FragmentCK
