import React from 'react'
import Fragment from '../common/fragment'

function FragmentAN(props) {
  return (
    <Fragment pos="n" {...props}>
      <p>
Ni el más resistente de los metales sobrevivirá al tiempo.
      </p>
    </Fragment>
  )
}


export default FragmentAN
