import React from 'react'
import Fragment from '../common/fragment'


function FragmentDJ(props) {
  return (
    <Fragment pos="jjjj" {...props}>
      <p>
METODO Y APARATO PARA CONTROLAR LA PRODUCCION DE NEGRO DE HUMO. EL
METODO COMPRENDE MEDIR A INTERVALOS ESPACIADOS AL MENOS UNA
VARIABLE DE ENTRADA USADA EN LA PRODUCCION DEL NEGRO DE HUMO;
EMPLEAR AL MENOS UN ALGORITMO PARA PREDECIR A INTERVALOS ESPACIADOS
AL MENOS UNA VARIABLE DE SALIDA DEL NEGRO DE HUMO USANDO LA
VARIABLE DE ENTRADA MEDIDA DURANTE EL INTERVALO ESPACIADO;
DETERMINAR, EN INTERVALOS PROMEDIOS ESPACIADOS, UN VALOR MEDIO DE
AL MENOS UNA VARIABLE DE SALIDA PREVISTA; Y AJUSTAR, EN INTERVALOS
ESPACIADOS, AL MENOS UNA DE LAS VARIABLES DE ENTRADA. EL APARATO
COMPRENDE MEDIOS DE MEDICION, DE COMPUTO Y DE AJUSTE PARA LLEVAR A
CABO LAS ETAPAS ANTERIORES
      </p>
    </Fragment>
  )
}


export default FragmentDJ
