import React from 'react'
import Fragment from '../common/fragment'

function FragmentAQ(props) {
  return (
    <Fragment pos="q" {...props}>
      <p>
Tome una botella transparente y guarde aire, luego ciérrela.
      </p>
    </Fragment>
  )
}


export default FragmentAQ
