import React from 'react'
import Fragment from '../common/fragment'


function FragmentBS(props) {
  return (
    <Fragment pos="ss" {...props}>
      <p>
Troya: ¿dónde están los muñecos? Salamanca: ¿qué habrá sido de los caballos?
      </p>
    </Fragment>
  )
}


export default FragmentBS
