import React from 'react'
import Fragment from '../common/fragment'

function FragmentCI(props) {
  return (
    <Fragment pos="iii" {...props}>
      <p>
Los cielos parecen abiertos.
      </p>
    </Fragment>
  )
}


export default FragmentCI
