import React from 'react'
import Fragment from '../common/fragment'


function FragmentCO(props) {
  return (
    <Fragment pos="ooo" {...props}>
      <p>
“Todo se ha disuelto, sólo han resistido los misterios” (Elio Arístides).
      </p>
    </Fragment>
  )
}


export default FragmentCO
